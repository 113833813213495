import { FC, useState } from 'react'

import { Col, Row, notification } from 'antd'

import { useController, useLoading } from '@data-client/react'

import { AccountResource } from '@peoplevine/sdk/api/account'
import { Button } from '@peoplevine/sdk/components/button/Button'
import Form, { Item } from '@peoplevine/sdk/components/form'
import { FormItem } from '@peoplevine/sdk/components/form/FormItem'
import { VerticalSpace } from '@peoplevine/sdk/components/layout'
import Text from '@peoplevine/sdk/components/text/Text'
import { Title } from '@peoplevine/sdk/components/text/Title'
import { AccountEntity, AccountUpdateProps } from '@peoplevine/sdk/datasource/account/account'
import { useNotification } from '@peoplevine/sdk/providers/Notification'

interface CreatePasswordProps {
  onSuccess: (data: AccountEntity) => void
  action: 'create' | 'change'
}

const CreatePassword: FC<CreatePasswordProps> = ({ action, onSuccess }) => {
  const { fetch } = useController()
  const { notifyOnError } = useNotification()
  const [enabled, setEnabled] = useState(false)

  const [onFinish, loading] = useLoading(async (password: Partial<AccountUpdateProps>) => {
    const body = { password } as Partial<AccountUpdateProps>
    await fetch(AccountResource.partialUpdate, body)
      .then((response) => {
        notification.success({
          message: 'Password Updated',
          description: 'Your new password has been updated',
        })
        onSuccess(response)
      })
      .catch(notifyOnError)
  }, [])

  return (
    <Row align={'middle'}>
      <Col span={24}>
        <VerticalSpace size={'large'}>
          <VerticalSpace size={0}>
            <Title level={1}>Create a Password</Title>
            <Text type={'secondary'}>Your account does not have a password created yet</Text>
            <Text type={'secondary'}>Let's secure your account with a new password </Text>
          </VerticalSpace>

          <Form<Partial<AccountUpdateProps>>
            name={'createPassword'}
            onFinish={onFinish}
            onValidate={setEnabled}
            disabled={loading}
          >
            <Item>
              <FormItem.Password name={'newPassword'} onValidate={setEnabled} showLevel />
            </Item>
            <Item>
              <Button icon={'directional/arrow-right'} loading={loading} disabled={!enabled} block htmlType={'submit'}>
                Continue
              </Button>
            </Item>
          </Form>
        </VerticalSpace>
      </Col>
    </Row>
  )
}

export { CreatePassword }
