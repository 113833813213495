import { FC } from 'react'

import { Skeleton } from 'antd'

import sanitizeHtml from 'sanitize-html'

import { useI18n } from 'src/providers'

import './Markup.scss'
import Text from './Text'

type MoneyProps = {
  icon?: Design.IvyIcon
  prefix: string
  suffix: string
  strike?: boolean
  wrap?: boolean
  strong?: boolean
  children: number
  currency?: string
} & Omit<SDK.Components.TextProps, 'children'>

type MarkupProps = SDK.Components.TextProps & {
  /**
   * @deprecated
   */
  html: string
  sanitized: boolean
  children: string
}

const Markup: FC<Partial<MarkupProps>> = ({ sanitized = true, html = '', children = html, loading = false }) => {
  return loading ? (
    <Skeleton />
  ) : children ? (
    !sanitized ? (
      <span
        className={'markup'}
        style={{ lineHeight: 1.5 }}
        data-cy={'Markup'}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(children.replaceAll('&nbsp;', ' ').trim(), {
            allowedTags: ['img', 'p', 'ul', 'li', 'ol', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'a', 'div', 'br'],
            parseStyleAttributes: true,
          }),
        }}
      />
    ) : (
      sanitizeHtml(children, { allowedTags: [] })
    )
  ) : null
}

const Money: FC<Partial<MoneyProps>> = ({
  icon,
  type,
  wrap = true,
  strong,
  prefix = '',
  suffix = '',
  strike = false,
  children = 0,
  currency,
  ...props
}) => {
  const { money } = useI18n()
  return (
    <Text
      {...props}
      type={type}
      strike={strike}
      strong={strong}
      context={{ amount: money(children, currency) }}
      icon={icon}
    >
      {[prefix, '{amount}', suffix].filter(Boolean).join(' ')}
    </Text>
  )
}

export { EmailLink } from './EmailLink'
export { Line } from './Line'
export { Link } from './Link'
export { PhoneLink } from './PhoneLink'
export { SmallText } from './SmallText'
export { default as Text } from './Text'
export { Title } from './Title'
export { Markup, Money }
