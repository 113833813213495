import { FC, type RefAttributes, forwardRef } from 'react'

import { Button as Wrapper } from 'antd'

import { useColor } from '../app'
import IvyIcon from '../icon'
import Text from '../text/Text'

const LoadingIcon = () => (
  <svg viewBox='0 0 1024 1024' focusable='false'>
    <path d='M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z' />
  </svg>
)

type ButtonProps = SDK.Components.ButtonProps & RefAttributes<HTMLButtonElement>

const Button: FC<ButtonProps> = forwardRef(
  (
    {
      type = 'primary',
      htmlType,
      title = '',
      goTo,
      children,
      theme,
      block,
      wide = block,
      outlined = false,
      shape,
      icon,
      ghost = false,
      href,
      context,
      disabled = false,
      loading = false,
      translate = 'yes',
      style,
      textProps,
      uppercased = true,
      ...props
    },
    ref,
  ) => {
    const { token, components, bgByVariant, color } = useColor()
    const borderColor = disabled
      ? components.Button?.borderColorDisabled
      : type === 'primary' || type === 'link'
        ? 'transparent'
        : token.colorBorder

    return (
      <Wrapper
        data-cy={'Button'}
        data-title={children}
        ref={ref}
        style={{
          width:
            block && shape === 'default'
              ? '100%'
              : type === 'link' && !children
                ? 'fit-content'
                : icon && !children
                  ? token.controlHeight
                  : undefined,
          height: type === 'link' && !children ? 'fit-content' : token.controlHeight,
          backgroundColor: theme ? bgByVariant(theme) : type === 'text' || type === 'link' ? 'transparent' : undefined,
          border: `1px solid ${borderColor}`,
          color:
            type === 'primary' || theme === 'danger'
              ? disabled
                ? token.colorTextDisabled
                : '#fff'
              : token.colorTextSecondary,
          textAlign: style?.textAlign ?? 'center',
          ...style,
        }}
        htmlType={htmlType}
        type={type}
        disabled={disabled}
        href={href}
        block={block || !icon || !children || !href}
        loading={loading}
        title={title}
        shape={shape}
        icon={
          loading ? (
            <LoadingIcon />
          ) : typeof icon === 'string' ? (
            <IvyIcon
              size={16}
              type={icon as Design.IvyIcon}
              color={type === 'primary' ? (color(token.colorPrimary)?.isDark() ? 'light' : 'dark') : 'secondary'}
              style={{
                color: style?.color ? style.color : undefined,
              }}
            />
          ) : (
            icon
          )
        }
        {...props}
      >
        {children ||
          (title && (
            <Text
              context={context}
              // icon={typeof icon === 'string' ? (icon as Design.IvyIcon) : undefined}
              color={
                disabled
                  ? borderColor
                  : style?.color
                    ? style.color
                    : type === 'text'
                      ? token.colorTextSecondary
                      : undefined
              }
              type={
                type === 'primary' && !disabled
                  ? color(token.colorPrimary)?.isDark()
                    ? 'light'
                    : 'dark'
                  : type === 'link'
                    ? 'primary'
                    : 'default'
              }
              translate={translate}
              uppercased={type !== 'link' && uppercased !== false}
              font={{ family: type === 'link' ? 'primary' : 'secondary' }}
              // underline={type === 'link'}
              {...textProps}
            >
              {children || title}
            </Text>
          ))}
      </Wrapper>
    )
  },
)

export { Button }
