import { useCallback } from 'react'

import { App, Flex, NotificationArgsProps } from 'antd'

import { ApiError } from '../api'
import { useColor } from '../components/app'
import { Button } from '../components/button/Button'
import IvyIcon from '../components/icon'
import Text from '../components/text/Text'
import './Notification.scss'

type ContextProps = {
  notify: (props: NotificationArgsProps) => void
  notifyOnError: (error: Partial<Pick<Error, 'name' | 'message'>>) => void
}

const iconMap: { [key in NonNullable<NotificationArgsProps['type']>]: Design.SymbolIcon } = {
  success: 'check-circle',
  error: 'warning',
  warning: 'warning',
  info: 'info-circle',
}
const useNotification: () => ContextProps = () => {
  const { notification } = App.useApp()
  const { bgByVariant, token, textByVariant } = useColor()
  const notify = useCallback(
    ({ type = 'success', message, description, closable = true, ...props }: NotificationArgsProps) => {
      notification.open({
        type,
        closable,
        btn: closable && (
          <Button
            type={'dashed'}
            onClick={() => notification.destroy()}
            style={{
              borderColor: 'transparent',
              backgroundColor: 'transparent',
              width: 'fit-content',
              height: 'fit-content',
              padding: 0,
              position: 'absolute',
              top: 15,
              right: 20,
            }}
            icon={'symbol/close'}
          />
        ),
        icon: (
          <IvyIcon
            color={type}
            type={`symbol/${iconMap[type] || 'info-circle'}`}
            style={{ display: 'flex', marginTop: -2 }}
            size={26}
          />
        ),
        closeIcon: false,
        style: {
          padding: '15px 33px 15px 20px',
          backgroundColor: bgByVariant(type, true),
          borderColor: bgByVariant(type),
          borderRadius: token.borderRadius,
          boxShadow:
            '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05)',
        },
        message:
          typeof message === 'string' ? (
            <Text strong color={textByVariant(type)} font={{ family: 'primary', size: 14, weight: 400 }}>
              {message}
            </Text>
          ) : (
            message
          ),
        description:
          typeof description === 'string' ? (
            <Text font={{ family: 'primary', size: 14, weight: 400 }}>{description}</Text>
          ) : (
            description
          ),
        ...props,
      })
    },
    [bgByVariant, notification, textByVariant, token.borderRadius],
  )

  const notifyOnError = useCallback(
    (error: Partial<ApiError>) => {
      console.log(error)
      notify({
        type: 'error',
        message: error?.name,
        description: error?.message ? (
          <Flex vertical gap={2}>
            <Text type={'error'}>{error.message}</Text>
            {error?.requestId && (
              <Text type={'secondary'} translate={'no'}>
                Request ID: <code>{error.requestId}</code>
              </Text>
            )}
          </Flex>
        ) : (
          error?.message
        ),
      })
    },
    [notify],
  )

  return { notify, notifyOnError }
}

export { useNotification }
